import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

domReady(() => {
  const accordions = $('.image-accordion-item');
  const mainImage = $('.accordionsMainImage');

  function changeAccordion(accordionIndex) {
    accordions.each(function (index) {
      const content = $(this).children('.image-accordion-item__content');
      const toggle = $(this).children('.image-button');
      if (index === accordionIndex) {
        $(this).addClass('opened');
        content.slideDown();
        toggle.attr('aria-expanded', 'true');
        mainImage.addClass('is-loading');
        setTimeout(function () {
          mainImage.attr('src', toggle.attr('data-image-url'));
          mainImage.attr('alt', toggle.attr('data-image-alt'));
          mainImage.attr('srcset', toggle.attr('data-image-srcset'));
        }, 250);
        setTimeout(function () {
          mainImage.removeClass('is-loading');
        }, 400);
      } else {
        $(this).removeClass('opened');
        content.slideUp();
        toggle.attr('aria-expanded', 'false');
      }
    });
  }

  accordions.each(function (index) {
    const content = $(this).children('.image-accordion-item__content');
    const toggle = $(this).children('.image-button');
    if ($(this).hasClass('opened')) {
      content.slideDown();
      toggle.attr('aria-expanded', 'true');
    }

    $(this).on('click', function () {
      if (!$(this).hasClass('opened')) {
        changeAccordion(index);
      }
    });
  });
});
